var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-spin",
    { staticStyle: { height: "990px" }, attrs: { spinning: _vm.loading } },
    [
      _c(
        "div",
        { staticClass: "all-card", style: _vm.backCss },
        [
          _c(
            "div",
            {
              ref: "leftCard",
              staticClass: "left-card",
              staticStyle: { width: "66.6%" },
            },
            [
              _c(
                "a-dropdown",
                {
                  attrs: { placement: "bottomLeft", trigger: ["contextmenu"] },
                  model: {
                    value: _vm.rightVisible,
                    callback: function ($$v) {
                      _vm.rightVisible = $$v
                    },
                    expression: "rightVisible",
                  },
                },
                [
                  _c(
                    "a-menu",
                    { attrs: { slot: "overlay" }, slot: "overlay" },
                    [
                      _c(
                        "a-menu-item",
                        { on: { click: _vm.changeWeacherImg } },
                        [_vm._v(" 修改背景 ")]
                      ),
                      _c("a-menu-item", { on: { click: _vm.changeUserImg } }, [
                        _vm._v(" 修改头像 "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "weather-card" },
                    [
                      _c("weather", {
                        ref: "weather",
                        attrs: { weather: _vm.weather },
                      }),
                      _vm.dateData
                        ? _c("div", { staticClass: "weather-text" }, [
                            _c("div", { staticClass: "weather-text-card" }, [
                              _c(
                                "div",
                                { staticClass: "weather-text-card-top" },
                                [
                                  _c("div", { staticClass: "weather-title" }, [
                                    _vm._v(_vm._s(_vm.dateData.gregorianDay)),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dateData.gregorianYear +
                                          "/" +
                                          _vm.dateData.gregorianMonth
                                      )
                                    ),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        "农历" +
                                          _vm.dateData.lunarMonthCn +
                                          _vm.dateData.lunarDayCn
                                      )
                                    ),
                                  ]),
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.dateData.weekday)),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "weather-text-card-bottom",
                                  on: { dblclick: _vm.editMotto },
                                },
                                [
                                  !_vm.mottoEdit
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.motto
                                              ? _vm.motto
                                              : "双击编辑座右铭"
                                          )
                                        ),
                                      ])
                                    : _c("a-input", {
                                        ref: "mottoInput",
                                        staticStyle: {
                                          border: "none",
                                          background: "rgba(0, 0, 0, 0)",
                                          color: "white",
                                        },
                                        attrs: { type: "text" },
                                        on: {
                                          pressEnter: _vm.changeMotto,
                                          blur: _vm.changeMotto,
                                        },
                                        model: {
                                          value: _vm.motto,
                                          callback: function ($$v) {
                                            _vm.motto = $$v
                                          },
                                          expression: "motto",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "weather-text-card" }, [
                              _c(
                                "div",
                                { staticClass: "weather-text-center-top" },
                                [
                                  _vm.formData.userFile.avatar
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: { position: "relative" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "select-pendant",
                                              style: `top:${_vm.pendant.top}px;left:${_vm.pendant.left}px;transform: rotate(${_vm.pendant.deg}deg);`,
                                            },
                                            [
                                              _vm.pendant.url
                                                ? _c("img", {
                                                    attrs: {
                                                      src: _vm.pendant.url,
                                                      alt: "",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "img-div" },
                                            [
                                              _c("img", {
                                                ref: "showImg",
                                                staticClass: "upload_img",
                                                attrs: {
                                                  src: _vm.formData.userFile
                                                    .avatar,
                                                  alt: "avatar",
                                                },
                                              }),
                                              _c("a-icon", {
                                                staticClass: "change-img",
                                                style: "color:" + _vm.color,
                                                attrs: { type: "plus" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticStyle: { position: "relative" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "select-pendant",
                                              style: `top:${_vm.pendant.top}px;left:${_vm.pendant.left}px;transform: rotate(${_vm.pendant.deg}deg);`,
                                            },
                                            [
                                              _vm.pendant.url
                                                ? _c("img", {
                                                    attrs: {
                                                      src: _vm.pendant.url,
                                                      alt: "",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "img-div" },
                                            [
                                              _c("img", {
                                                ref: "showImg",
                                                staticClass: "upload_img",
                                                attrs: {
                                                  src: require("@/assets/tx.png"),
                                                  alt: "avatar",
                                                },
                                              }),
                                              _c("a-icon", {
                                                staticClass: "change-img",
                                                style: "color:" + _vm.color,
                                                attrs: { type: "plus" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "weather-text-center-bottom" },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "您好，" + _vm._s(_vm.userInfo.nickName)
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "weather-text-card" }, [
                              _c(
                                "div",
                                { staticClass: "weather-text-card-top-right" },
                                [
                                  !_vm.isSearchCity
                                    ? _c(
                                        "div",
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "environment" },
                                            on: { click: _vm.openCitySearch },
                                          }),
                                          _vm.cityName
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "8px",
                                                  },
                                                },
                                                [_vm._v(_vm._s(_vm.cityName))]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              staticStyle: { width: "200px" },
                                              attrs: {
                                                "show-search": "",
                                                value: _vm.cityName,
                                                placeholder:
                                                  "搜索全球城市及地区",
                                                "default-active-first-option": false,
                                                "show-arrow": false,
                                                "filter-option": false,
                                                "not-found-content": null,
                                              },
                                              on: {
                                                search: _vm.handleSearch,
                                                change: _vm.handleChange,
                                              },
                                            },
                                            _vm._l(_vm.citys, function (d) {
                                              return _c(
                                                "a-select-option",
                                                { key: d.id },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(d.name) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              )
                                            }),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                  _vm.dateData.now
                                    ? _c("div", [
                                        _vm.dateData.now.icon
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-right": "5px",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  class:
                                                    "qi-" +
                                                    _vm.dateData.now.icon +
                                                    " weather-icon",
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "weather-title",
                                            staticStyle: {
                                              "margin-left": "8px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.dateData.now.temp) +
                                                "°C"
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.dateData.now
                                    ? _c("div", [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.dateData.now.text)),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "8px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.dateData.now.windDir)
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "weather-text-card-bottomRight",
                                },
                                [
                                  _c("div", [
                                    !_vm.toDayText.text
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "box",
                                            attrs: { id: "mood" },
                                          },
                                          [
                                            _c("span", {
                                              staticClass:
                                                "iconfont icon-check-circle icon front",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.boxClick.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            }),
                                            _c("div", {
                                              staticClass:
                                                "back iconfont icon-check-circle icon",
                                            }),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "box",
                                            attrs: { id: "mood" },
                                          },
                                          [
                                            _c("span", {
                                              ref: "backIcon",
                                              staticClass:
                                                "iconfont icon-check-circle icon front",
                                            }),
                                          ]
                                        ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "sign",
                                        staticStyle: {
                                          opacity: "0",
                                          height: "0",
                                        },
                                      },
                                      [_vm._v("点击打卡")]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "today-text" }, [
                                    _vm.toDayText.text
                                      ? _c("div", [_vm._v("今日宜")])
                                      : _c(
                                          "div",
                                          { staticStyle: { opacity: "0" } },
                                          [_vm._v("今日宜")]
                                        ),
                                    _vm.toDayText.text
                                      ? _c("div", [
                                          _vm._v(_vm._s(_vm.toDayText.text)),
                                        ])
                                      : _c(
                                          "div",
                                          {
                                            staticStyle: { cursor: "pointer" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.boxClick.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("点击打卡")]
                                        ),
                                    _c("div", { staticClass: "sign-num" }, [
                                      _vm._v(
                                        "已累计打卡" +
                                          _vm._s(
                                            _vm.firstData.user &&
                                              _vm.firstData.user.continuedSignIn
                                          ) +
                                          "天"
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "left-card-bottom" },
                [
                  _c(
                    "a-row",
                    { staticStyle: { height: "100%" } },
                    [
                      _c(
                        "a-col",
                        {
                          staticStyle: { "padding-right": "15px" },
                          attrs: { span: 12 },
                        },
                        _vm._l(_vm.classNames, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: item.key,
                              class: item,
                              style: !index ? "" : _vm.dragStyle,
                            },
                            [
                              item == "left-card-bottom-lt"
                                ? _c("finish-and-start", {
                                    ref: "fAnds",
                                    refInFor: true,
                                    attrs: {
                                      loading: _vm.startLoading,
                                      firstData: _vm.firstData,
                                    },
                                    on: { getFirstData: _vm.getStartData },
                                  })
                                : _vm._e(),
                              item == "left-card-bottom-lb"
                                ? _c("product-carousel", {
                                    attrs: {
                                      loading: _vm.productLoading,
                                      pols: _vm.firstData.pols || [],
                                    },
                                    on: { getFirstData: _vm.getProductData },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c(
                        "a-col",
                        {
                          staticStyle: { "padding-left": "15px" },
                          attrs: { span: 12 },
                        },
                        _vm._l(_vm.classNames2, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: item.key,
                              class: item,
                              style: !index ? "" : _vm.dragStyle,
                            },
                            [
                              item == "left-card-bottom-rt"
                                ? _c("user-msg", {
                                    on: {
                                      showAnnouncement: _vm.showAnnouncement,
                                    },
                                  })
                                : _vm._e(),
                              item == "left-card-bottom-rb"
                                ? _c("qAnda")
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              ref: "rightCard",
              staticClass: "right-card",
              staticStyle: { width: "33.3%" },
            },
            _vm._l(_vm.classNames3, function (item, index) {
              return _c(
                "div",
                {
                  key: item.key,
                  class: item,
                  style: !index ? "" : _vm.dragStyle,
                },
                [
                  item == "right-top-card"
                    ? _c("home-mobile-calendar", {
                        ref: "homeMobileCalendar",
                        refInFor: true,
                        attrs: {
                          userInfo: _vm.formData,
                          firstData: _vm.firstData,
                        },
                        on: { getAllData: _vm.getAllData },
                      })
                    : _vm._e(),
                  item == "right-bottom-card"
                    ? _c("img-center", { attrs: { firstData: _vm.firstData } })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
          _c("user-cropper-model", {
            ref: "userCropperModel",
            on: { upImg: _vm.upImg },
          }),
          _c(
            "a-modal",
            {
              staticClass: "login-user",
              attrs: {
                keyboard: false,
                "mask-closable": false,
                closable: false,
                width: "700px",
              },
              scopedSlots: _vm._u([
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _vm.tab == "2"
                        ? _c(
                            "a-button",
                            { key: "back", on: { click: _vm.closeUserModal } },
                            [_vm._v(" 关闭 ")]
                          )
                        : _vm._e(),
                      _c(
                        "a-button",
                        {
                          key: "submit",
                          attrs: { type: "primary", loading: _vm.btnLoading },
                          on: { click: _vm.handleUserInfo },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.tab == "1" ? "下一步" : "保存") +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.userModalVisible,
                callback: function ($$v) {
                  _vm.userModalVisible = $$v
                },
                expression: "userModalVisible",
              },
            },
            [
              _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v("\n          填写个人信息：\n          "),
                _c(
                  "span",
                  { staticStyle: { color: "red", "font-size": "12px" } },
                  [
                    _vm._v(
                      "最迟需在第一次登录系统后的次日24点前填写完毕，否则无法正常进入系统。"
                    ),
                  ]
                ),
              ]),
              _c("login-user-modal", {
                ref: "userForm",
                attrs: { tab: _vm.tab, userInfo: _vm.userInfo },
                on: {
                  changeTabs: _vm.changeTabs,
                  changeTab: _vm.changeTab,
                  openCropper: _vm.openCropper,
                  closeUserModal: _vm.closeUserModal,
                },
              }),
            ],
            1
          ),
          _c("user-cropper-model", {
            ref: "userCropperModel",
            on: { upImg: _vm.openImg },
          }),
          _c(
            "a-modal",
            {
              attrs: {
                keyboard: false,
                "mask-closable": false,
                closable: false,
                title: "填写工作排期",
                width: _vm.scheduleIsDaily ? "35%" : "50%",
              },
              scopedSlots: _vm._u([
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "a-button",
                        {
                          key: "submit",
                          attrs: { type: "primary" },
                          on: { click: _vm.saveWork },
                        },
                        [_vm._v(" 保存 ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.workVisible,
                callback: function ($$v) {
                  _vm.workVisible = $$v
                },
                expression: "workVisible",
              },
            },
            [
              _c(
                "a-form-model",
                _vm._b(
                  {
                    ref: "workForm",
                    attrs: {
                      model: _vm.formData.userFile,
                      rules: _vm.validatorRules,
                    },
                  },
                  "a-form-model",
                  _vm.workLayout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { prop: "workDays", label: "通常每周可工作日" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            mode: "multiple",
                            placeholder: "通常每周可工作日",
                          },
                          on: { change: _vm.workDaysChange },
                          model: {
                            value: _vm.formData.userFile.workDays,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData.userFile, "workDays", $$v)
                            },
                            expression: "formData.userFile.workDays",
                          },
                        },
                        [
                          _c(
                            "a-select-option",
                            { attrs: { value: 1, disabled: _vm.selectDis } },
                            [_vm._v("周一")]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: 2, disabled: _vm.selectDis } },
                            [_vm._v("周二")]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: 3, disabled: _vm.selectDis } },
                            [_vm._v("周三")]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: 4, disabled: _vm.selectDis } },
                            [_vm._v("周四")]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: 5, disabled: _vm.selectDis } },
                            [_vm._v("周五")]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: 6, disabled: _vm.selectDis } },
                            [_vm._v("周六")]
                          ),
                          _c(
                            "a-select-option",
                            { attrs: { value: 7, disabled: _vm.selectDis } },
                            [_vm._v("周日")]
                          ),
                          _c("a-select-option", { attrs: { value: -1 } }, [
                            _vm._v("跳过中国法定节假日"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.labelList.filter(
                    (item) =>
                      item.depart.departName.indexOf("日语部") > -1 ||
                      item.depart.departName.indexOf("韩语部") > -1
                  ).length && !_vm.isAdmin
                    ? _c("div", { staticStyle: { "margin-bottom": "24px" } }, [
                        _vm._v(
                          "\n            工作日单一职位最大任务数量：\n          "
                        ),
                      ])
                    : _vm._e(),
                  _vm.labelList.filter(
                    (item) =>
                      item.depart.departName.indexOf("日语部") > -1 ||
                      item.depart.departName.indexOf("韩语部") > -1
                  ).length && !_vm.isAdmin
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            color: "red",
                            "font-size": "12px",
                            position: "relative",
                            top: "-11px",
                          },
                        },
                        [
                          _vm._v(
                            "\n            注意：请在输入框内填写当天只做单一阶段的最大任务数量。\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.labelList.filter(
                    (item) =>
                      item.depart.departName.indexOf("日语部") > -1 ||
                      item.depart.departName.indexOf("韩语部") > -1
                  ).length && !_vm.isAdmin
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            color: "red",
                            "font-size": "12px",
                            position: "relative",
                            top: "-11px",
                          },
                        },
                        [
                          _vm._v(
                            "\n            例，一天只做翻译，能做10话则填入10\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.labelList.filter(
                    (item) =>
                      item.depart.departName.indexOf("日语部") > -1 ||
                      item.depart.departName.indexOf("韩语部") > -1
                  ).length && !_vm.isAdmin
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-bottom": "24px",
                            "margin-top": "10px",
                          },
                        },
                        [
                          _c(
                            "a-radio-group",
                            {
                              model: {
                                value: _vm.scheduleIsDaily,
                                callback: function ($$v) {
                                  _vm.scheduleIsDaily = $$v
                                },
                                expression: "scheduleIsDaily",
                              },
                            },
                            [
                              _c("a-radio", { attrs: { value: 1 } }, [
                                _vm._v("每日数值相同"),
                              ]),
                              _c(
                                "a-radio",
                                {
                                  attrs: {
                                    value: 0,
                                    disabled: Array.from(
                                      _vm.formData.userFile.workDays || []
                                    ).some((item) => item == -1),
                                  },
                                },
                                [_vm._v("每日数值不同")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isAdmin &&
                  _vm.labelList.filter(
                    (item) =>
                      item.week == "WORK_DAY" &&
                      (item.depart.departName.indexOf("日语部") > -1 ||
                        item.depart.departName.indexOf("韩语部") > -1)
                  ).length &&
                  _vm.scheduleIsDaily
                    ? _vm._l(
                        _vm.labelList.filter(
                          (item) =>
                            item.week == "WORK_DAY" &&
                            (item.depart.departName.indexOf("日语部") > -1 ||
                              item.depart.departName.indexOf("韩语部") > -1)
                        ),
                        function (item) {
                          return _c(
                            "a-form-model-item",
                            {
                              key: item.departId + "-" + item.positionId,
                              attrs: {
                                prop: "workCount",
                                label:
                                  item.position && item.position.positionName
                                    ? (item.depart && item.depart.departName) +
                                      "-" +
                                      item.position.positionName
                                    : "",
                              },
                            },
                            [
                              _c("a-input-number", {
                                attrs: {
                                  min: 0,
                                  placeholder: "请输入可工作数量",
                                },
                                on: { blur: _vm.workBlur },
                                model: {
                                  value: item.count,
                                  callback: function ($$v) {
                                    _vm.$set(item, "count", $$v)
                                  },
                                  expression: "item.count",
                                },
                              }),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  !_vm.isAdmin &&
                  _vm.labelList.filter(
                    (item) =>
                      item.week == "WORK_DAY" &&
                      (item.depart.departName.indexOf("日语部") > -1 ||
                        item.depart.departName.indexOf("韩语部") > -1)
                  ).length &&
                  !_vm.scheduleIsDaily
                    ? _c("a-table", {
                        attrs: {
                          columns: _vm.columns.filter((item, index) =>
                            _vm.formData.userFile.workDays.some(
                              (i) => i == index || !index
                            )
                          ),
                          scroll: { x: "100%", y: "350px" },
                          pagination: false,
                          "data-source": _vm.weekTableData.filter(
                            (data) =>
                              data.departAndPosition.indexOf("日语部") > -1 ||
                              data.departAndPosition.indexOf("韩语部") > -1
                          ),
                          bordered: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm._l(
                              [
                                "MONDAY",
                                "TUESDAY",
                                "WEDNESDAY",
                                "THURSDAY",
                                "FRIDAY",
                                "SATURDAY",
                                "SUNDAY",
                              ],
                              function (col, idx) {
                                return {
                                  key: col,
                                  fn: function (text, record, index) {
                                    return [
                                      _c(
                                        "div",
                                        { key: idx },
                                        [
                                          _c("a-input-number", {
                                            staticStyle: { margin: "-5px 0" },
                                            attrs: {
                                              min: 0,
                                              value: record[col],
                                            },
                                            on: {
                                              change: (e) =>
                                                _vm.handleWorkChange(
                                                  e,
                                                  record.departAndPosition,
                                                  col
                                                ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                }
                              }
                            ),
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                keyboard: false,
                "mask-closable": false,
                closable: false,
                title: "个人信息",
                width: "35%",
              },
              scopedSlots: _vm._u([
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "a-button",
                        {
                          key: "submit",
                          attrs: { type: "primary" },
                          on: { click: _vm.saveSelf },
                        },
                        [_vm._v(" 保存 ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.selfInfoShow,
                callback: function ($$v) {
                  _vm.selfInfoShow = $$v
                },
                expression: "selfInfoShow",
              },
            },
            [
              _c(
                "a-form-model",
                _vm._b(
                  {
                    ref: "selfForm",
                    attrs: {
                      model: _vm.formData.userFile,
                      rules: _vm.validatorSelfRules,
                    },
                  },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "relative",
                        height: "20px",
                        "padding-bottom": "42px",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            position: "absolute",
                            "font-size": "12px",
                            top: "0",
                            color: "#aca9a9",
                          },
                        },
                        [_vm._v("下列信息仅BOSS及HR有权查看，请如实填写。")]
                      ),
                    ]
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "姓名", prop: "userName" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入姓名" },
                        model: {
                          value: _vm.formData.userFile.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData.userFile, "userName", $$v)
                          },
                          expression: "formData.userFile.userName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "性别", prop: "sex" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.formData.userFile.sex,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData.userFile, "sex", $$v)
                            },
                            expression: "formData.userFile.sex",
                          },
                        },
                        [
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("男"),
                          ]),
                          _c("a-radio", { attrs: { value: 0 } }, [
                            _vm._v("女"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "出生日期", prop: "bornDate" } },
                    [
                      _c("a-date-picker", {
                        attrs: { format: "YYYY-MM-DD" },
                        model: {
                          value: _vm.formData.userFile.bornDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData.userFile, "bornDate", $$v)
                          },
                          expression: "formData.userFile.bornDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "工作地城市" } },
                    [
                      _c("a-cascader", {
                        attrs: {
                          options: _vm.options,
                          allowClear: "",
                          defaultValue: _vm.formData.userFile.workCity,
                          "change-on-select": "",
                          "show-search": { filter: _vm.filter },
                          placeholder: "请选择工作地城市",
                        },
                        on: { change: _vm.onChange },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "国籍", prop: "country" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "国籍" },
                          model: {
                            value: _vm.formData.userFile.country,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData.userFile, "country", $$v)
                            },
                            expression: "formData.userFile.country",
                          },
                        },
                        [
                          _c("a-select-option", { attrs: { value: "中国" } }, [
                            _vm._v("中国"),
                          ]),
                          _c("a-select-option", { attrs: { value: "美国" } }, [
                            _vm._v("美国"),
                          ]),
                          _c("a-select-option", { attrs: { value: "英国" } }, [
                            _vm._v("英国"),
                          ]),
                          _c("a-select-option", { attrs: { value: "日本" } }, [
                            _vm._v("日本"),
                          ]),
                          _c("a-select-option", { attrs: { value: "韩国" } }, [
                            _vm._v("韩国"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "电话号码", prop: "phone" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入电话号码" },
                        model: {
                          value: _vm.formData.userFile.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData.userFile, "phone", $$v)
                          },
                          expression: "formData.userFile.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("weacher-img", {
            ref: "weacherImg",
            attrs: { color: _vm.color, userInfoWeacher: _vm.userInfo },
            on: { changeBackImg: _vm.changeBackImg },
          }),
          _c("user-img", {
            ref: "userImg",
            attrs: { color: _vm.color, userInfoWeacher: _vm.userInfo },
            on: { editUserImg: _vm.editUserImg },
          }),
          _c("show-announcement", { ref: "ShowAnnouncement" }),
          _c("dynamic-notice", {
            ref: "showDynamNotice",
            attrs: { path: _vm.openPath, formData: _vm.msgData },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }