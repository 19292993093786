export const toDayTexts = [
  '烧烤',
  '吃火锅',
  '理发',
  '做家务',
  '跑步',
  '喝咖啡',
  '散步',
  '追剧',
  '涂鸦',
  '打扮',
  '写日记',
  '买彩票',
  '喝奶茶',
  '喝茶',
  '洗头',
  '吃垃圾食品',
  '摆拍',
  'P图',
  '换壁纸',
  '遛狗',
  '撸猫',
  '拥抱',
  '吃瓜',
  '摸鱼',
  '开黑',
  '听歌',
  '存钱',
  '剁手',
  '请客',
  '洗衣服',
  '唱歌',
  '做饭',
  '钓鱼',
  'DIY',
  '夜跑',
  '沉思',
  '加油',
  '冥想',
  '约会',
  '追番',
  '倾诉',
  '撒娇',
  '看书',
  '化妆',
  '打扮',
  '清空购物车',
  '做计划',
  '整理桌面',
  '发红包',
  '给家人打电话',
  '夸奖同桌',
  '比个爱心',
  '卖萌',
  '暴饮暴食',
  '抛个媚眼',
  '补充维C',
  '放飞自我',
  '控制饭量',
  '清理手机内存',
  '清理相册',
  '吃零食',
  '看新闻',
  '按摩',
  '逛夜市',
  '吃甜品',
  '发朋友圈',
  '写封信',
  '取消订阅',
  '做性格测试',
  '修剪指甲',
  'get新技能',
  '换头像',
  '锻炼',
  '护肤'
]
