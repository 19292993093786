<template>
  <a-spin style="height: 990px" :spinning="loading">
    <div :style="backCss" class="all-card">
      <div style="width: 66.6%" ref="leftCard" class="left-card">
        <a-dropdown v-model="rightVisible" :placement="'bottomLeft'" :trigger="['contextmenu']">
          <a-menu slot="overlay">
            <a-menu-item @click="changeWeacherImg"> 修改背景 </a-menu-item>
            <a-menu-item @click="changeUserImg"> 修改头像 </a-menu-item>
          </a-menu>
          <div class="weather-card">
            <weather ref="weather" :weather="weather" />
            <div v-if="dateData" class="weather-text">
              <div class="weather-text-card">
                <div class="weather-text-card-top">
                  <div class="weather-title">{{ dateData.gregorianDay }}</div>
                  <div>{{ dateData.gregorianYear + '/' + dateData.gregorianMonth }}</div>
                  <div>{{ '农历' + dateData.lunarMonthCn + dateData.lunarDayCn }}</div>
                  <div>{{ dateData.weekday }}</div>
                </div>
                <div class="weather-text-card-bottom" @dblclick="editMotto">
                  <span v-if="!mottoEdit">{{ motto ? motto : '双击编辑座右铭' }}</span>
                  <a-input
                    v-else
                    ref="mottoInput"
                    @pressEnter="changeMotto"
                    @blur="changeMotto"
                    type="text"
                    style="border: none; background: rgba(0, 0, 0, 0); color: white"
                    v-model="motto"
                  />
                </div>
              </div>
              <div class="weather-text-card">
                <div class="weather-text-center-top">
                  <div v-if="formData.userFile.avatar" style="position: relative">
                    <div
                      class="select-pendant"
                      :style="`top:${pendant.top}px;left:${pendant.left}px;transform: rotate(${pendant.deg}deg);`"
                    >
                      <img v-if="pendant.url" :src="pendant.url" alt="" />
                    </div>
                    <div class="img-div">
                      <img ref="showImg" :src="formData.userFile.avatar" alt="avatar" class="upload_img" />
                      <a-icon :style="'color:' + color" class="change-img" type="plus" />
                    </div>
                  </div>
                  <div v-else style="position: relative">
                    <div
                      class="select-pendant"
                      :style="`top:${pendant.top}px;left:${pendant.left}px;transform: rotate(${pendant.deg}deg);`"
                    >
                      <img v-if="pendant.url" :src="pendant.url" alt="" />
                    </div>
                    <div class="img-div">
                      <img ref="showImg" src="~@/assets/tx.png" alt="avatar" class="upload_img" />
                      <a-icon :style="'color:' + color" class="change-img" type="plus" />
                    </div>
                  </div>
                </div>
                <div class="weather-text-center-bottom">
                  <div>您好，{{ userInfo.nickName }}</div>
                </div>
              </div>
              <div class="weather-text-card">
                <div class="weather-text-card-top-right">
                  <div v-if="!isSearchCity">
                    <a-icon type="environment" @click="openCitySearch" />
                    <span style="margin-left: 8px" v-if="cityName">{{ cityName }}</span>
                  </div>
                  <div v-else>
                    <a-select
                      show-search
                      :value="cityName"
                      placeholder="搜索全球城市及地区"
                      style="width: 200px"
                      :default-active-first-option="false"
                      :show-arrow="false"
                      :filter-option="false"
                      :not-found-content="null"
                      @search="handleSearch"
                      @change="handleChange"
                    >
                      <a-select-option v-for="d in citys" :key="d.id">
                        {{ d.name }}
                      </a-select-option>
                    </a-select>
                  </div>
                  <div v-if="dateData.now">
                    <span v-if="dateData.now.icon" style="margin-right: 5px">
                      <i :class="'qi-' + dateData.now.icon + ' weather-icon'"></i>
                    </span>
                    <span style="margin-left: 8px" class="weather-title">{{ dateData.now.temp }}°C</span>
                  </div>
                  <div v-if="dateData.now">
                    <span>{{ dateData.now.text }}</span>
                    <span style="margin-left: 8px">{{ dateData.now.windDir }}</span>
                  </div>
                </div>
                <div class="weather-text-card-bottomRight">
                  <div>
                    <div v-if="!toDayText.text" class="box" id="mood">
                      <span @click.stop="boxClick" class="iconfont icon-check-circle icon front"></span>
                      <div class="back iconfont icon-check-circle icon"></div>
                    </div>
                    <div v-else class="box" id="mood">
                      <span ref="backIcon" class="iconfont icon-check-circle icon front"></span>
                    </div>
                    <div style="opacity: 0; height: 0" class="sign">点击打卡</div>
                  </div>
                  <div class="today-text">
                    <div v-if="toDayText.text">今日宜</div>
                    <div v-else style="opacity: 0">今日宜</div>
                    <div v-if="toDayText.text">{{ toDayText.text }}</div>
                    <div v-else @click.stop="boxClick" style="cursor: pointer">点击打卡</div>
                    <div class="sign-num">已累计打卡{{ firstData.user && firstData.user.continuedSignIn }}天</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-dropdown>
        <div class="left-card-bottom">
          <a-row style="height: 100%">
            <a-col :span="12" style="padding-right: 15px">
              <!--               <draggable
                dragClass="drag-img"
                ghostClass="ghost"
                group="list"
                :animation="300"
                style="height:100%"
                v-model="classNames"
                @start="drag = true"
                @end="drag = false"
              >
                <div v-for="(item, index) in classNames" :class="item" :key="item.key" :style="!index ? '' : dragStyle">
                  <finish-and-start
                    ref="fAnds"
                    v-if="item == 'left-card-bottom-lt'"
                    :firstData="firstData"
                  />
                  <product-carousel
                    :pols="firstData.pols || []"
                    v-if="item == 'left-card-bottom-lb'"
                  />
                </div>
              </draggable> -->
              <div v-for="(item, index) in classNames" :class="item" :key="item.key" :style="!index ? '' : dragStyle">
                <finish-and-start
                  :loading="startLoading"
                  @getFirstData="getStartData"
                  ref="fAnds"
                  v-if="item == 'left-card-bottom-lt'"
                  :firstData="firstData"
                /><!-- 仪表盘 -->
                <product-carousel
                  :loading="productLoading"
                  @getFirstData="getProductData"
                  :pols="firstData.pols || []"
                  v-if="item == 'left-card-bottom-lb'"
                /><!-- 最新上线作品 -->
              </div>
            </a-col>
            <a-col :span="12" style="padding-left: 15px">
              <!--               <draggable
                dragClass="drag-img"
                ghostClass="ghost"
                group="list1"
                style="height:100%"
                :animation="300"
                v-model="classNames2"
                @start="drag = true"
                @end="drag = false"
              >
                <div
                  v-for="(item, index) in classNames2"
                  :class="item"
                  :key="item.key"
                  :style="!index ? '' : dragStyle"
                >
                  <user-msg v-if="item == 'left-card-bottom-rt'" />
                  <qAnda v-if="item == 'left-card-bottom-rb'" />
                </div>
              </draggable> -->
              <div v-for="(item, index) in classNames2" :class="item" :key="item.key" :style="!index ? '' : dragStyle">
                <user-msg v-if="item == 'left-card-bottom-rt'" @showAnnouncement="showAnnouncement" /><!-- 公告 -->
                <qAnda v-if="item == 'left-card-bottom-rb'" /><!-- Q&A -->
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div style="width: 33.3%" ref="rightCard" class="right-card">
        <!--         <draggable
          dragClass="drag-img"
          ghostClass="ghost"
          group="list2"
          :animation="300"
          v-model="classNames3"
          @start="drag = true"
          @end="drag = false"
        >
          <div v-for="(item, index) in classNames3" :class="item" :key="item.key" :style="!index ? '' : dragStyle">
            <home-mobile-calendar :userInfo='formData' ref='homeMobileCalendar' v-if="item == 'right-top-card'" :firstData="firstData" />
          </div>
        </draggable> -->
        <div v-for="(item, index) in classNames3" :class="item" :key="item.key" :style="!index ? '' : dragStyle">
          <home-mobile-calendar
            :userInfo="formData"
            @getAllData="getAllData"
            ref="homeMobileCalendar"
            v-if="item == 'right-top-card'"
            :firstData="firstData"
          /><!-- 日历 -->
          <img-center :firstData="firstData" v-if="item == 'right-bottom-card'" />
        </div>
      </div>
      <user-cropper-model ref="userCropperModel" @upImg="upImg"> </user-cropper-model>
      <!-- 登陆成功后弹框 -->
      <a-modal
        :keyboard="false"
        class="login-user"
        :mask-closable="false"
        :closable="false"
        v-model="userModalVisible"
        width="700px"
      >
        <div slot="title">
          填写个人信息：
          <span style="color: red; font-size: 12px"
            >最迟需在第一次登录系统后的次日24点前填写完毕，否则无法正常进入系统。</span
          >
        </div>
        <login-user-modal
          @changeTabs="changeTabs"
          @changeTab="changeTab"
          @openCropper="openCropper"
          :tab="tab"
          ref="userForm"
          @closeUserModal="closeUserModal"
          :userInfo="userInfo"
        ></login-user-modal>
        <template #footer>
          <a-button key="back" @click="closeUserModal" v-if="tab == '2'"> 关闭 </a-button>
          <a-button key="submit" type="primary" :loading="btnLoading" @click="handleUserInfo">
            {{ tab == '1' ? '下一步' : '保存' }}
          </a-button>
        </template>
      </a-modal>

      <!-- 先把新用户登录后要看人员须知那些隐藏掉吧，一上来就填信息就行 -->
      <!-- <a-modal
      :keyboard="false"
      :mask-closable="false"
      :closable="false"
      v-model="memberNoticeVisible"
      title="阅读人员须知"
      width="80%"
    >
      <member-notice ref="memberNotice" is-preview @allTableConfirm="allTableConfirm"></member-notice>
      <template #footer>
        <a-tooltip title="阅读完所有人员须知后方可确认">
          <a-button
            :disabled="confirmBtnDisable"
            key="submit"
            type="primary"
            :loading="btnLoading"
            @click="handleMemberNotice"
          >
            确认
          </a-button>
        </a-tooltip>
      </template>
    </a-modal> -->

      <!-- 有新消息弹框 -->
      <user-cropper-model ref="userCropperModel" @upImg="openImg"> </user-cropper-model>
      <!--       <a-modal
        :keyboard="false"
        :mask-closable="false"
        :closable="false"
        v-model="workVisible"
        title="请选择可工作日"
        width="35%"
      >
        <a-form-model :model="formData.userFile" ref="workForm" :rules="validatorRules" v-bind="layout">
          <a-form-model-item prop="workDays" label="通常每周可工作日">
            <a-select
              mode="multiple"
              placeholder="通常每周可工作日"
              v-model="formData.userFile.workDays"
              @change="workDaysChange"
            >
              <a-select-option :value="1" :disabled="selectDis">周一</a-select-option>
              <a-select-option :value="2" :disabled="selectDis">周二</a-select-option>
              <a-select-option :value="3" :disabled="selectDis">周三</a-select-option>
              <a-select-option :value="4" :disabled="selectDis">周四</a-select-option>
              <a-select-option :value="5" :disabled="selectDis">周五</a-select-option>
              <a-select-option :value="6" :disabled="selectDis">周六</a-select-option>
              <a-select-option :value="7" :disabled="selectDis">周日</a-select-option>
              <a-select-option :value="-1">跳过中国法定节假日</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="workdayAvailableCount" label="工作日每天可安排数量">
            <a-input-number :min="0" v-model="formData.userFile.workdayAvailableCount" @change="workCountChange" />
          </a-form-model-item>
        </a-form-model>
        <template #footer>
          <a-button key="submit" type="primary" @click="saveWork"> 保存 </a-button>
        </template>
      </a-modal> -->
      <a-modal
        :keyboard="false"
        :mask-closable="false"
        :closable="false"
        v-model="workVisible"
        title="填写工作排期"
        :width="scheduleIsDaily ? '35%' : '50%'"
      >
        <a-form-model :model="formData.userFile" ref="workForm" :rules="validatorRules" v-bind="workLayout">
          <a-form-model-item prop="workDays" label="通常每周可工作日">
            <a-select
              mode="multiple"
              placeholder="通常每周可工作日"
              v-model="formData.userFile.workDays"
              @change="workDaysChange"
            >
              <a-select-option :value="1" :disabled="selectDis">周一</a-select-option>
              <a-select-option :value="2" :disabled="selectDis">周二</a-select-option>
              <a-select-option :value="3" :disabled="selectDis">周三</a-select-option>
              <a-select-option :value="4" :disabled="selectDis">周四</a-select-option>
              <a-select-option :value="5" :disabled="selectDis">周五</a-select-option>
              <a-select-option :value="6" :disabled="selectDis">周六</a-select-option>
              <a-select-option :value="7" :disabled="selectDis">周日</a-select-option>
              <a-select-option :value="-1">跳过中国法定节假日</a-select-option>
            </a-select>
          </a-form-model-item>
          <div
            v-if="
              labelList.filter(
                item => item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1
              ).length && !isAdmin
            "
            style="margin-bottom:24px;"
          >
            工作日单一职位最大任务数量：
          </div>
          <div
            v-if="
              labelList.filter(
                item => item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1
              ).length && !isAdmin
            "
            style="color:red;font-size:12px;position:relative;top:-11px;"
          >
            注意：请在输入框内填写当天只做单一阶段的最大任务数量。
          </div>
          <div
            v-if="
              labelList.filter(
                item => item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1
              ).length && !isAdmin
            "
            style="color:red;font-size:12px;position:relative;top:-11px;"
          >
            例，一天只做翻译，能做10话则填入10
          </div>
          <div
            v-if="
              labelList.filter(
                item => item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1
              ).length && !isAdmin
            "
            style="margin-bottom:24px;margin-top:10px;"
          >
            <a-radio-group v-model="scheduleIsDaily">
              <a-radio :value="1">每日数值相同</a-radio>
              <a-radio :value="0" :disabled="Array.from(formData.userFile.workDays || []).some(item => item == -1)"
                >每日数值不同</a-radio
              >
            </a-radio-group>
          </div>
          <template
            v-if="
              !isAdmin &&
                labelList.filter(
                  item =>
                    item.week == 'WORK_DAY' &&
                    (item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1)
                ).length &&
                scheduleIsDaily
            "
          >
            <a-form-model-item
              v-for="item in labelList.filter(
                item =>
                  item.week == 'WORK_DAY' &&
                  (item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1)
              )"
              :key="item.departId + '-' + item.positionId"
              prop="workCount"
              :label="
                item.position && item.position.positionName
                  ? (item.depart && item.depart.departName) + '-' + item.position.positionName
                  : ''
              "
            >
              <a-input-number :min="0" placeholder="请输入可工作数量" v-model="item.count" @blur="workBlur" />
            </a-form-model-item>
          </template>
          <a-table
            v-if="
              !isAdmin &&
                labelList.filter(
                  item =>
                    item.week == 'WORK_DAY' &&
                    (item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1)
                ).length &&
                !scheduleIsDaily
            "
            :columns="columns.filter((item, index) => formData.userFile.workDays.some(i => i == index || !index))"
            :scroll="{ x: '100%', y: '350px' }"
            :pagination="false"
            :data-source="
              weekTableData.filter(
                data => data.departAndPosition.indexOf('日语部') > -1 || data.departAndPosition.indexOf('韩语部') > -1
              )
            "
            bordered
          >
            <template
              v-for="(col, idx) in ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']"
              :slot="col"
              slot-scope="text, record, index"
            >
              <div :key="idx">
                <a-input-number
                  :min="0"
                  style="margin: -5px 0"
                  :value="record[col]"
                  @change="e => handleWorkChange(e, record.departAndPosition, col)"
                />
              </div>
            </template>
          </a-table>
        </a-form-model>
        <template #footer>
          <a-button key="submit" type="primary" @click="saveWork"> 保存 </a-button>
        </template>
      </a-modal>
      <a-modal
        :keyboard="false"
        :mask-closable="false"
        :closable="false"
        v-model="selfInfoShow"
        title="个人信息"
        width="35%"
      >
        <a-form-model :model="formData.userFile" ref="selfForm" :rules="validatorSelfRules" v-bind="layout">
          <div style="position: relative; height: 20px; padding-bottom: 42px">
            <span style="position: absolute; font-size:12px; top: 0; color: #aca9a9"
              >下列信息仅BOSS及HR有权查看，请如实填写。</span
            >
          </div>
          <a-form-model-item label="姓名" prop="userName">
            <a-input placeholder="请输入姓名" v-model="formData.userFile.userName" />
          </a-form-model-item>
          <a-form-model-item label="性别" prop="sex">
            <a-radio-group v-model="formData.userFile.sex">
              <a-radio :value="1">男</a-radio>
              <a-radio :value="0">女</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="出生日期" prop="bornDate">
            <a-date-picker format="YYYY-MM-DD" v-model="formData.userFile.bornDate" />
          </a-form-model-item>
          <a-form-model-item label="工作地城市">
            <a-cascader
              :options="options"
              allowClear
              :defaultValue="formData.userFile.workCity"
              change-on-select
              :show-search="{ filter }"
              placeholder="请选择工作地城市"
              @change="onChange"
            />
          </a-form-model-item>
          <a-form-model-item label="国籍" prop="country">
            <a-select placeholder="国籍" v-model="formData.userFile.country">
              <a-select-option value="中国">中国</a-select-option>
              <a-select-option value="美国">美国</a-select-option>
              <a-select-option value="英国">英国</a-select-option>
              <a-select-option value="日本">日本</a-select-option>
              <a-select-option value="韩国">韩国</a-select-option>
            </a-select>
          </a-form-model-item>
<!--           <a-form-model-item v-if="formData.userFile.country == '中国'" prop="nation" label="民族">
            <a-auto-complete placeholder="民族" v-model="formData.userFile.nation">
              <template slot="dataSource">
                <a-select-option v-for="n in nationList" :key="n">{{ n }}</a-select-option>
              </template>
            </a-auto-complete>
          </a-form-model-item> -->
          <a-form-model-item label="电话号码" prop="phone">
            <a-input placeholder="请输入电话号码" v-model="formData.userFile.phone" />
          </a-form-model-item>
<!--           <a-form-model-item label="学历" prop="education">
            <a-select placeholder="学历" v-model="formData.userFile.education">
              <a-select-option value="博士">博士</a-select-option>
              <a-select-option value="硕士">硕士</a-select-option>
              <a-select-option value="本科">本科</a-select-option>
              <a-select-option value="专科">专科</a-select-option>
              <a-select-option value="高中">高中</a-select-option>
              <a-select-option value="初中">初中</a-select-option>
              <a-select-option value="小学">小学</a-select-option>
            </a-select>
          </a-form-model-item> -->
<!--           <a-form-model-item label="毕业院校" prop="school">
            <a-input placeholder="请输入毕业院校" v-model="formData.userFile.school" />
          </a-form-model-item> -->
<!--           <a-form-model-item label="职位相关证书" prop="certificate">
            <a-input placeholder="请输入职位相关证书" v-model="formData.userFile.certificate" />
          </a-form-model-item> -->
<!--           <a-form-model-item label="工作经历" prop="workExperience">
            <a-textarea
              autoSize
              placeholder="请输入和现在职位相关的工作经历"
              v-model="formData.userFile.workExperience"
            />
          </a-form-model-item> -->
<!--           <template
            v-if="
              (country == 'qt' || country == 'gat') &&
                formData.userFile.contractType != '意向全职' &&
                formData.userFile.contractType != '兼职'
            "
          >
            <div style="padding-top: 10px; padding-bottom: 15px">
              <span style="display: inline-block; width: 160px; font-weight: bold; font-size: 14px"
                >英文银行账户信息</span
              >
              <span style="font-size:12px; color: rgba(0, 0, 0, 0.25)">以下内容除收款账号，其余内容请输入英文信息</span>
            </div>
            <a-form-model-item label="户名" prop="paymentName">
              <a-input placeholder="请输入户名" v-model="formData.userFile.paymentName" />
            </a-form-model-item>
            <a-form-model-item label="收款账号" prop="alipayOtherAccount">
              <a-input placeholder="请输入收款账号" v-model="formData.userFile.bankAccount" />
            </a-form-model-item>
            <a-form-model-item label="银行名称" prop="bankName">
              <a-input placeholder="请输入银行名称" v-model="formData.userFile.bankName" />
            </a-form-model-item>
            <a-form-model-item label="支行名称" prop="branchBank">
              <a-input placeholder="请输入支行名称" v-model="formData.userFile.branchBank" />
            </a-form-model-item>
            <a-form-model-item label="SWIFT CODE" prop="paymentBank">
              <a-input placeholder="请输入SWIFT CODE" v-model="formData.userFile.paymentBank" />
            </a-form-model-item>
            <a-form-model-item label="银行地址" prop="bankAddress">
              <a-input placeholder="请输入银行地址" v-model="formData.userFile.bankAddress" />
            </a-form-model-item>
            <a-form-model-item label="个人地址">
              <a-input placeholder="请输入个人地址" v-model="formData.userFile.personalAddress" />
            </a-form-model-item>
          </template>
          <template v-else>
            <div>
              <a-form-model-item
                label="收款账号"
                :prop="
                  country != 'qt' &&
                  country != 'gat' &&
                  formData.userFile.contractType != '意向全职' &&
                  formData.userFile.contractType != '兼职'
                    ? 'alipayAccount'
                    : 'alipayOtherAccount'
                "
              >
                <a-select
                  placeholder="请选择收款方式"
                  v-if="formData.userFile.contractType == '意向全职' || formData.userFile.contractType == '兼职'"
                  @change="paymentTypeChange"
                  :default-value="formData.userFile.paymentType"
                >
                  <a-select-option value="EN_ACCOUNT">
                    <a-icon type="bank" style="width: 1vw; position: relative; left: -3px" />
                    全英账户</a-select-option
                  >
                  <a-select-option value="ALIPAY">
                    <a-icon type="alipay-circle" style="width: 1vw; position: relative; left: -3px" />
                    支付宝</a-select-option
                  >
                  <a-select-option value="WECHAT">
                    <a-icon type="wechat" style="width: 1vw; position: relative; left: -3px" />
                    微信</a-select-option
                  >
                  <a-select-option
                    value="BANK"
                    v-if="
                      formData.userFile &&
                        formData.userFile.workCity &&
                        typeof formData.userFile.workCity === 'object' &&
                        formData.userFile.workCity.some(item => item == 83)
                    "
                  >
                    <a-icon type="credit-card" style="width: 1vw; position: relative; left: -3px" />
                    银行账户</a-select-option
                  >
                </a-select>
                <a-input
                  v-if="
                    country != 'qt' &&
                      country != 'gat' &&
                      formData.userFile.contractType != '意向全职' &&
                      formData.userFile.contractType != '兼职'
                  "
                  :defaultValue="formData.userFile.alipayAccount"
                  placeholder="请输入招行卡号"
                  @blur="alipayAccountBlur"
                >
                  <img
                    slot="prefix"
                    src="./../../../assets/zh.png"
                    alt=""
                    style="width: 1vw; position: relative; left: -4px"
                  />
                </a-input>
                <a-input
                  v-if="formData.userFile.paymentType == 'ALIPAY'"
                  placeholder="请输入支付宝账号"
                  v-model="formData.userFile.alipayOtherAccount"
                >
                </a-input>
                <a-input
                  v-if="formData.userFile.paymentType == 'WECHAT'"
                  placeholder="请输入微信账号"
                  v-model="formData.userFile.alipayOtherAccount"
                >
                </a-input>
                <template
                  v-if="
                    formData.userFile.paymentType == 'BANK' &&
                      formData.userFile.contractType != '全职' &&
                      formData.userFile.contractType != '实习'
                  "
                >
                  <a-select
                    show-search
                    placeholder="请选择开户行"
                    option-filter-prop="label"
                    v-if="country != 'qt' && country != 'gat'"
                    v-model="formData.userFile.bankName"
                  >
                    <a-select-option :label="item.text" v-for="item in bankList" :key="item.value">{{
                      item.text
                    }}</a-select-option>
                  </a-select>
                  <a-input
                    v-if="country != 'qt' && country != 'gat'"
                    placeholder="请输入银行卡号"
                    @blur="submitBank"
                    v-model="formData.userFile.bankAccount"
                  />
                  <a-input
                    v-if="country != 'qt' && country != 'gat'"
                    placeholder="请输入户名"
                    v-model="formData.userFile.paymentName"
                  />
                </template>
              </a-form-model-item>
              <template v-if="formData.userFile.paymentType == 'EN_ACCOUNT'">
                <div style="padding-top: 10px; padding-bottom: 15px">
                  <span style="display: inline-block; width: 160px; font-weight: bold; font-size: 14px"
                    >英文银行账户信息</span
                  >
                  <span style="font-size:12px; color: rgba(0, 0, 0, 0.25)"
                    >以下内容除收款账号，其余内容请输入英文信息</span
                  >
                </div>
                <a-form-model-item label="户名">
                  <a-input placeholder="请输入户名" v-model="formData.userFile.paymentName" />
                </a-form-model-item>
                <a-form-model-item label="收款账号" prop="alipayOtherAccount">
                  <a-input placeholder="请输入收款账号" v-model="formData.userFile.bankAccount" />
                </a-form-model-item>
                <a-form-model-item label="银行名称">
                  <a-input placeholder="请输入银行名称" v-model="formData.userFile.bankName" />
                </a-form-model-item>
                <a-form-model-item label="支行名称">
                  <a-input placeholder="请输入支行名称" v-model="formData.userFile.branchBank" />
                </a-form-model-item>
                <a-form-model-item label="SWIFT CODE">
                  <a-input placeholder="请输入SWIFT CODE" v-model="formData.userFile.paymentBank" />
                </a-form-model-item>
                <a-form-model-item label="银行地址">
                  <a-input placeholder="请输入银行地址" v-model="formData.userFile.bankAddress" />
                </a-form-model-item>
                <a-form-model-item label="个人地址">
                  <a-input placeholder="请输入个人地址" v-model="formData.userFile.personalAddress" />
                </a-form-model-item>
              </template>
            </div>
          </template>
          <template
            v-if="
              formData.userFile.paymentType == 'BANK' &&
                formData.userFile.contractType != '全职' &&
                formData.userFile.contractType != '实习' &&
                country == 'qt'
            "
          >
            <a-form-model-item label="名前片假名">
              <a-input placeholder="请输入名前片假名" v-model="formData.userFile.paymentName" />
            </a-form-model-item>
            <a-form-model-item label="銀行名">
              <a-input placeholder="请输入銀行名" v-model="formData.userFile.bankName" />
            </a-form-model-item>
            <a-form-model-item label="店番号">
              <a-input placeholder="请输入店番号" v-model="formData.userFile.bankAccount" />
            </a-form-model-item>
            <a-form-model-item label="支店名">
              <a-input placeholder="请输入支店名" v-model="formData.userFile.branchBank" />
            </a-form-model-item>
            <a-form-model-item label="口座番号">
              <a-input placeholder="请输入口座番号" v-model="formData.userFile.bankAddress" />
            </a-form-model-item>
            <a-form-model-item label="預金種別">
              <a-input placeholder="请输入預金種別" v-model="formData.userFile.personalAddress" />
            </a-form-model-item>
          </template> -->
<!--           <span style="color: red; font-size:12px; position: relative; top: -3px"
            >*本人承诺以上所填信息真实有效，若有虚假，本人自愿承担带来的经济损失</span
          > -->
        </a-form-model>
        <template #footer>
          <a-button key="submit" type="primary" @click="saveSelf"> 保存 </a-button>
        </template>
      </a-modal>
      <weacher-img
        :color="color"
        @changeBackImg="changeBackImg"
        :userInfoWeacher="userInfo"
        ref="weacherImg"
      ></weacher-img>
      <user-img :color="color" @editUserImg="editUserImg" :userInfoWeacher="userInfo" ref="userImg"></user-img>
      <show-announcement ref="ShowAnnouncement"></show-announcement>
      <dynamic-notice ref="showDynamNotice" :path="openPath" :formData="msgData" />
    </div>
  </a-spin>
</template>

<script>
import { postAction, getAction, putAction } from '@/api/manage'
import Vue from 'vue'
import Calendar from '@/utils/nongli.js'
import weather from './page/weather.vue'
import productCarousel from './page/productCarousel.vue'
import LoginUserModal from '@/views/dashboard/loginUserModal'
import finishAndStart from './page/finishAndStart.vue'
import userMsg from './page/userMsg.vue'
import qAnda from './page/qAnda.vue'
import weacherImg from './page/weacherImg.vue'
import userImg from './page/userImg.vue'
import homeMobileCalendar from './page/homeMobileCalendar.vue'
import DynamicNotice from '@/components/tools/DynamicNotice'
import imgCenter from './page/imgCenter.vue'
import axios from 'axios'
import draggable from 'vuedraggable'
import { iconNames } from './page/iconName.js'
import { toDayTexts } from './page/toDayText.js'
import userCropperModel from './cropper-model.vue'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import ShowAnnouncement from '@/components/tools/ShowAnnouncement'
import { citys } from './page/city.js'
import { simpleDebounce } from '@/utils/util'
export default {
  mixins: [UploadFileToOssMixins],
  components: {
    weacherImg,
    userImg,
    imgCenter,
    weather,
    draggable,
    userCropperModel,
    productCarousel,
    finishAndStart,
    userMsg,
    qAnda,
    homeMobileCalendar,
    ShowAnnouncement,
    LoginUserModal,
    DynamicNotice
  },
  data() {
    let validatePay = (rule, value, callback) => {
      if (!this.formData.alipayOtherAccount) {
        callback(new Error('请填写收款账号'))
      } else {
        callback()
      }
    }
    let workCountVal = (rule, value, callback) => {
      if (this.labelList.some(item => !item.count)) {
        callback(new Error('请填写所有职位可工作数量'))
      } else {
        callback()
      }
    }
    return {
      color: '',
      msgData: '',
      rightVisible: false,
      layout: {
        labelCol: {
          style: 'width: 160px',
          span: 7
        },
        wrapperCol: {
          span: 17
        }
      },
      workLayout: {
        labelCol: {
          style: 'width: 220px',
          span: 10
        },
        wrapperCol: {
          span: 14
        }
      },
      columns: [
        {
          title: '部门-职位（每日可工作数量）',
          dataIndex: 'departAndPosition',
          width: '30%',
          scopedSlots: { customRender: 'departAndPosition' }
        },
        {
          title: '周一',
          dataIndex: 'MONDAY',
          width: '10%',
          scopedSlots: { customRender: 'MONDAY' }
        },
        {
          title: '周二',
          dataIndex: 'TUESDAY',
          width: '10%',
          scopedSlots: { customRender: 'TUESDAY' }
        },
        {
          title: '周三',
          dataIndex: 'WEDNESDAY',
          width: '10%',
          scopedSlots: { customRender: 'WEDNESDAY' }
        },
        {
          title: '周四',
          dataIndex: 'THURSDAY',
          width: '10%',
          scopedSlots: { customRender: 'THURSDAY' }
        },
        {
          title: '周五',
          dataIndex: 'FRIDAY',
          width: '10%',
          scopedSlots: { customRender: 'FRIDAY' }
        },
        {
          title: '周六',
          dataIndex: 'SATURDAY',
          width: '10%',
          scopedSlots: { customRender: 'SATURDAY' }
        },
        {
          title: '周日',
          dataIndex: 'SUNDAY',
          width: '10%',
          scopedSlots: { customRender: 'SUNDAY' }
        }
      ],
      typeList: [
        { value: '门派', checked: false },
        { value: '武侠', checked: false },
        { value: '玄幻', checked: false },
        { value: '奇幻', checked: false },
        { value: '异能', checked: false },
        { value: '仙侠', checked: false },
        { value: '复仇', checked: false },
        { value: '动作', checked: false },
        { value: '恋爱', checked: false },
        { value: '霸凌', checked: false },
        { value: '惊悚', checked: false },
        { value: '丧尸', checked: false },
        { value: '竞技', checked: false },
        { value: '冒险', checked: false },
        { value: 'AI', checked: false },
        { value: '搞笑', checked: false },
        { value: '豪门霸总', checked: false },
        { value: '都市', checked: false },
        { value: '契约', checked: false },
        { value: '养成', checked: false },
        { value: '治愈', checked: false },
        { value: '悬疑', checked: false },
        { value: '料理', checked: false },
        { value: '妖怪', checked: false },
        { value: '精灵', checked: false },
        { value: '医学', checked: false },
        { value: '科幻', checked: false },
        { value: '军事', checked: false },
        { value: '同人', checked: false },
        { value: '热血', checked: false },
        { value: '暗黑', checked: false },
        { value: '美食', checked: false },
        { value: '逆袭', checked: false },
        { value: '励志', checked: false },
        { value: '伦理', checked: false },
        { value: '性转', checked: false },
        { value: '宫斗', checked: false },
        { value: '烧脑', checked: false },
        { value: '其他', checked: false }
      ],
      typeListTwo: [
        { value: '门派', checked: false },
        { value: '武侠', checked: false },
        { value: '玄幻', checked: false },
        { value: '奇幻', checked: false },
        { value: '异能', checked: false },
        { value: '仙侠', checked: false },
        { value: '复仇', checked: false },
        { value: '动作', checked: false },
        { value: '恋爱', checked: false },
        { value: '霸凌', checked: false },
        { value: '惊悚', checked: false },
        { value: '丧尸', checked: false },
        { value: '竞技', checked: false },
        { value: '冒险', checked: false },
        { value: 'AI', checked: false },
        { value: '搞笑', checked: false },
        { value: '豪门霸总', checked: false },
        { value: '都市', checked: false },
        { value: '契约', checked: false },
        { value: '养成', checked: false },
        { value: '治愈', checked: false },
        { value: '悬疑', checked: false },
        { value: '料理', checked: false },
        { value: '妖怪', checked: false },
        { value: '精灵', checked: false },
        { value: '医学', checked: false },
        { value: '科幻', checked: false },
        { value: '军事', checked: false },
        { value: '同人', checked: false },
        { value: '热血', checked: false },
        { value: '暗黑', checked: false },
        { value: '美食', checked: false },
        { value: '逆袭', checked: false },
        { value: '励志', checked: false },
        { value: '伦理', checked: false },
        { value: '性转', checked: false },
        { value: '宫斗', checked: false },
        { value: '烧脑', checked: false },
        { value: '其他', checked: false }
      ],
      animate: false,
      loading: false,
      mottoEdit: false,
      dateData: null,
      selfInfoShow: false,
      isAdmin: false,
      formData: {
        userFile: {
          avatar: ''
        }
      },
      avatarLoading: false,
      dragStyle: 'margin-top:30px',
      classNames: ['left-card-bottom-lt', 'left-card-bottom-lb'],
      classNames2: ['left-card-bottom-rt', 'left-card-bottom-rb'],
      classNames3: ['right-top-card', 'right-bottom-card'],
      marqueeList: [],
      fileList: [],
      firstData: {},
      toDayText: {
        text: '',
        name: '',
        color: '#fff'
      },
      motto: '',
      pendant: { url: '' },
      weather: 'sun',
      isSearchCity: false,
      searchCityId: '101270119',
      cityName: '武侯',
      citys: [],
      workVisible: false,
      tab: '1',
      bankList: [],
      tabListNoTitle: [
        {
          key: 'task',
          tab: '最近任务'
        },
        /*         {
          key: 'rating',
          tab: '评级'
        },
        {
          key: 'settlement',
          tab: '结算'
        }, */
        {
          key: 'user-file',
          tab: '个人信息'
        }
      ],
      selectDis: false,
      validatorRules: {
        workdayAvailableCount: [{ required: true, message: '请输入工作日每天可安排数量', trigger: 'blur' }],
        workDays: [{ required: true, message: '请选择每周可工作日', trigger: 'blur' }],
        workCount: [{ validator: workCountVal, trigger: 'blur' }]
      },
      nationList: [
        '汉族',
        '满族',
        '蒙古族',
        '回族',
        '藏族',
        '维吾尔族',
        '苗族',
        '彝族',
        '壮族',
        '布依族',
        '侗族',
        '瑶族',
        '白族',
        '土家族',
        '哈尼族',
        '哈萨克族',
        '傣族',
        '黎族',
        '傈僳族',
        '佤族',
        '畲族',
        '高山族',
        '拉祜族',
        '水族',
        '东乡族',
        '纳西族',
        '景颇族',
        '柯尔克孜族',
        '土族',
        '达斡尔族',
        '仫佬族',
        '羌族',
        '布朗族',
        '撒拉族',
        '毛南族',
        '仡佬族',
        '锡伯族',
        '阿昌族',
        '普米族',
        '朝鲜族',
        '塔吉克族',
        '怒族',
        '乌孜别克族',
        '俄罗斯族',
        '鄂温克族',
        '德昂族',
        '保安族',
        '裕固族',
        '京族',
        '塔塔尔族',
        '独龙族',
        '鄂伦春族',
        '赫哲族',
        '门巴族',
        '珞巴族',
        '基诺族'
      ],
      validatorSelfRules: {
        userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        bornDate: [{ required: true, message: '请选择出生日期', trigger: 'change' }],
        country: [{ required: true, message: '请选择国籍', trigger: 'blur' }],
        nation: [{ required: true, message: '请选择民族', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入电话号码', trigger: 'blur' }],
        education: [{ required: true, message: '请选择学历', trigger: 'blur' }],
        school: [{ required: true, message: '请选择毕业院校', trigger: 'blur' }],
        certificate: [{ required: true, message: '请输入职业相关证书', trigger: 'blur' }],
        workExperience: [{ required: true, message: '请输入相关工作经历', trigger: 'blur' }],
        bankAccount: [{ required: true, message: '请输入收款账号', trigger: 'blur' }],
        paymentName: [{ required: true, message: '请输入英文户名', trigger: 'blur' }],
        bankName: [{ required: true, message: '请输入银行名称', trigger: 'blur' }],
        branchBank: [{ required: true, message: '请输入支行名称', trigger: 'blur' }],
        paymentBank: [{ required: true, message: '请输入SWIFT CODE', trigger: 'blur' }],
        bankAddress: [{ required: true, message: '请输入银行地址', trigger: 'blur' }],
        personalAddress: [{ required: true, message: '请输入个人地址', trigger: 'blur' }],
        alipayAccount: [
          {
            required: true,
            message: '银行卡号格式错误，请重新输入',
            trigger: 'blur',
            pattern: /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/
          }
        ],
        alipayOtherAccount: [{ validator: validatePay, trigger: 'change' }]
      },
      noTitleKey: 'task',
      confirmBtnDisable: true,
      country: '',
      btnLoading: false,
      userModalVisible: false,
      userInfo: {},
      openPath: '',
      startLoading: false,
      productLoading: false,
      labelList: [],
      weekTableData: [],
      workEditable: false,
      scheduleIsDaily: 1,
      options: [],
      weekMap: {
        MONDAY: '周一',
        TUESDAY: '周二',
        WEDNESDAY: '周三',
        THURSDAY: '周四',
        FRIDAY: '周五',
        SATURDAY: '周六',
        SUNDAY: '周日'
      }
    }
  },
  created() {
    const userInfo = Vue.ls.get(USER_INFO)
    this.userInfo = userInfo
    this.bankList = citys.bankList
    this.options = citys.options
    let { id, userFile } = userInfo
    if (!userFile.workDays) userFile.workDays = []
    this.formData = {
      ...this.formData,
      id,
      userFile
    }
    if (this.formData.userFile) {
      if (this.formData.userFile.workCity && typeof this.formData.userFile.workCity != 'object') {
        this.formData.userFile.workCity = this.formData.userFile.workCity.split(',')
        this.country = this.formData.userFile.workCity[0]
      }
      if (this.formData.userFile.badAtProductionType && typeof this.formData.userFile.badAtProductionType != 'object') {
        this.formData.userFile.badAtProductionType = this.formData.userFile.badAtProductionType.split(',')
        this.formData.userFile.badAtProductionType.forEach(item => {
          this.typeListTwo.forEach(type => {
            if (type.value == item) {
              type.checked = true
            }
          })
          if (item == '其他') {
            this.dcShow = true
          }
        })
      }
      if (
        this.formData.userFile.goodAtProductionType &&
        typeof this.formData.userFile.goodAtProductionType != 'object'
      ) {
        this.formData.userFile.goodAtProductionType = this.formData.userFile.goodAtProductionType.split(',')
        this.formData.userFile.goodAtProductionType.forEach(item => {
          this.typeList.forEach(type => {
            if (type.value == item) {
              type.checked = true
            }
          })
          if (item == '其他') {
            this.scShow = true
          }
        })
      }
      if (!this.formData.userFile.workDays) this.formData.userFile.workDays = []
    }
    this.userModalVisible = this.userInfo.userFile.needUserGuide
    if (!this.userInfo.userFile.needUserGuide) {
      this.workVisible = this.userInfo.userFile.needWorkDays
      if (
        this.userInfo.roles.some(item => {
          return (
            item.roleCode == 'admin' || item.roleCode == 'boss' || item.roleCode == 'tongchou' || item.roleCode == 'hr'
          )
        })
      ) {
        this.workVisible = false
      }
    }
    if (this.userInfo.roles) {
      this.userInfo.roles.forEach(one => {
        if (one.roleCode == 'admin' || one.roleCode == 'boss' || one.roleCode == 'hr') {
          this.isAdmin = true
        }
      })
    }
    this.getWeather()
    this.getWorkNum()
    this.getFirstData()
    this.$bus.$on('reload', () => {
      this.getWeather()
      this.getWorkNum()
      this.getFirstData()
    })
  },
  mounted() {
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        if (this.$refs.homeMobileCalendar) {
          this.$refs.homeMobileCalendar[0].changeWidth()
        }
      })()
    }
  },
  watch: {},
  computed: {
    backCss() {
      /* let colorBack = this.color */
      this.color = this.$store.state.app.color
      return {
        /* '--back-color': colorBack, */
        '--theme-color': this.color
      }
    }
  },
  methods: {
    getAllData() {
      this.getWeather()
      this.getWorkNum()
      this.getFirstData()
    },
    onChange(value) {
      this.country = value[0] || ''
      this.formData.userFile.workCity = value
      this.formData.userFile.paymentType = undefined
    },
    filter(inputValue, path) {
      console.log(path)
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    },
    async changeBackImg(backUrl) {
      await putAction('/sys/user/backUrl?now=' + backUrl)
      this.getFirstData()
    },
    changeWeacherImg() {
      this.rightVisible = false
      if (this.$refs.weacherImg) this.$refs.weacherImg.open(this.firstData, this.dateData)
    },
    changeUserImg() {
      this.rightVisible = false
      if (this.$refs.userImg) this.$refs.userImg.open(this.pendant)
    },
    async editUserImg(data) {
      if (data.selectName) {
        await putAction('/sys/user/headwear?now=' + data.selectName.id)
      }
      console.log(data.imgUrl)
      if (data.imgUrl) {
        this.formData.userFile.avatar = data.imgUrl
      }
      this.getFirstData()
    },
    workBlur() {
      this.$refs['workForm'].validate(v => {})
    },
    async getWorkNum() {
      const res = await getAction('/taskSchedule?userId=' + this.userInfo.id)
      if (res.code == 200) {
        this.labelList = res.data
        this.weekTableData = []
        this.workEditable = false
        this.scheduleIsDaily = this.userInfo.userFile.scheduleIsDaily ? 1 : 0
        this.labelList
          ?.filter(item => item.week != 'WORK_DAY')
          ?.forEach(item => {
            if (
              this.weekTableData.every(
                i => i.departAndPosition != item.depart.departName + '-' + item.position.positionName
              )
            ) {
              this.weekTableData.push({
                departAndPosition: item.depart.departName + '-' + item.position.positionName,
                editable: false,
                key: item.depart.departId + '-' + item.position.positionId
              })
            }
          })
        this.labelList
          ?.filter(item => item.week != 'WORK_DAY')
          ?.forEach(item => {
            this.weekTableData.forEach(i => {
              if (i.departAndPosition == item.depart.departName + '-' + item.position.positionName) {
                if (item.week == 'MONDAY') {
                  i.MONDAY = item.count
                } else if (item.week == 'TUESDAY') {
                  i.TUESDAY = item.count
                } else if (item.week == 'WEDNESDAY') {
                  i.WEDNESDAY = item.count
                } else if (item.week == 'THURSDAY') {
                  i.THURSDAY = item.count
                } else if (item.week == 'FRIDAY') {
                  i.FRIDAY = item.count
                } else if (item.week == 'SATURDAY') {
                  i.SATURDAY = item.count
                } else if (item.week == 'SUNDAY') {
                  i.SUNDAY = item.count
                }
              }
            })
          })
      }
    },
    getProductData() {
      this.productLoading = true
      this.getFirstData()
    },
    getStartData() {
      this.startLoading = true
      this.getFirstData()
    },
    showAnnouncement(record) {
      putAction('sys/sysAnnouncementSend/editByAnntIdAndUserId', { anntId: record.anntId }).then(res => {
        if (res.success) {
          this.syncHeadNotic(record.anntId)
        }
      })
      if (record.openType === 'component') {
        this.openPath = record.openPage
        this.msgData = { id: record.busId }
        this.$refs.showDynamNotice.detail()
      } else {
        this.$refs.ShowAnnouncement.detail(record)
      }
    },
    syncHeadNotic(anntId) {
      getAction('sys/annountCement/syncNotic', { anntId: anntId })
    },
    async changeTab(isTab) {
      this.btnLoading = true
      setTimeout(() => (this.btnLoading = false), 2000)
      this.$refs.userForm.saveForm(() => {
        // 先把新用户登录后要看人员须知那些隐藏掉吧，一上来就填信息就行
        putAction('/sys/user/changeNeedUserGuideFalse').then(async ({ success }) => {
          if (success) {
            // this.$message.success('您已完成新手指引')
            this.userInfo.userFile.needUserGuide = false
            Vue.ls.set(USER_INFO, this.userInfo, 7 * 24 * 60 * 60 * 1000)
          } else {
            // this.$message.error('新手指引完成失败了，请联系管理员')
          }
        })
      }, true)
    },
    changeTabs(key) {
      this.tab = key
    },
    submitBank() {
      let str = this.formData.userFile.bankAccount.replace(/\s*/g, '')
      axios
        .get(
          `https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?_input_charset=utf-8&cardNo=${str}&cardBinCheck=true`
        )
        .then(res => {
          const { bank, cardType, validated, messages } = res.data
          if (validated) {
            this.formData.userFile.bankName = bank
          }
        })
    },
    paymentTypeChange(value) {
      this.formData.userFile.paymentType = value
      this.formData.userFile.alipayOtherAccount = ''
      this.formData.userFile.alipayAccount = ''
      this.$forceUpdate()
    },
    async closeUserModal() {
      this.userModalVisible = false
      /* this.memberNoticeVisible = true */
      this.btnLoading = false
      this.workVisible = this.userInfo.userFile.needWorkDays
      await putAction('/sys/user/changeWorkInfoTimer', { workInfoTimer: this.getCurrentTime() })
      if (!this.userInfo.userFile.needWorkDays) {
        window.location.reload()
      }
    },
    getCurrentTime() {
      var date = new Date() //当前时间
      var year = date.getFullYear() //返回指定日期的年份
      var month = repair(date.getMonth() + 1) //月
      var day = repair(date.getDate()) //日
      var hour = repair(date.getHours()) //时
      var minute = repair(date.getMinutes()) //分
      var second = repair(date.getSeconds()) //秒
      function repair(i) {
        if (i >= 0 && i <= 9) {
          return '0' + i
        } else {
          return i
        }
      }
      //当前时间
      var curTime = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
      return curTime
    },
    workCountChange() {
      if (
        this.formData.userFile.workDays.some(item => {
          return item == 8
        })
      ) {
        this.selectDis = true
        this.formData.userFile.workDays = [8]
      } else {
        this.selectDis = false
      }
      let n1 = this.formData.userFile.workdayAvailableCount || 0
      if (this.formData.userFile.weekendAvailableCount !== undefined) {
        if (
          this.formData.userFile.workDays.every(item => {
            return item != 6 && item != 7 && item != 8
          })
        ) {
          this.formData.userFile.weekendAvailableCount = 0
        }
      }
      let n2 = this.formData.userFile.weekendAvailableCount || 0
      let arr = this.formData.userFile.workDays?.filter(item => {
        return item != 8
      })
      let length = arr ? arr.length : 0
      this.formData.userFile.weekAvailableCount = n1 * length + n2
      // 月可安排 this.formData.userFile.monthAvailableCount = this.formData.userFile.weekAvailableCount * 4
    },
    workDaysChange() {
      if (
        this.formData.userFile.workDays.some(item => {
          return item == -1
        })
      ) {
        this.selectDis = true
        this.formData.userFile.workDays = [-1]
        this.scheduleIsDaily = 1
      } else {
        this.selectDis = false
      }
      let n1 = this.formData.userFile.workdayAvailableCount || 0
      if (this.formData.userFile.weekendAvailableCount !== undefined) {
        if (
          this.formData.userFile.workDays.every(item => {
            return item != 6 && item != 7 && item != -1
          })
        ) {
          this.formData.userFile.weekendAvailableCount = 0
        } else {
        }
      }
      let n2 = this.formData.userFile.weekendAvailableCount || 0
      let arr = this.formData.userFile.workDays?.filter(item => {
        return item != 8
      })
      let length = arr ? arr.length : 0
      this.formData.userFile.weekAvailableCount = n1 * length + n2
      /* this.formData.userFile.monthAvailableCount = this.formData.userFile.weekAvailableCount * 4 月可安排 */
    },
    alipayAccountBlur(e) {
      this.formData.userFile.alipayAccount = e.target.value.replace(/\s*/g, '')
    },
    async handleUserInfo() {
      this.btnLoading = true
      if (this.tab == '1') {
        putAction('/sys/user/changeWorkInfoTimer', { workInfoTimer: this.getCurrentTime() })
      } else {
        if (
          this.$refs.userForm.formData.userFile.userName &&
          this.$refs.userForm.formData.userFile.sex &&
          this.$refs.userForm.formData.userFile.bornDate &&
          this.$refs.userForm.formData.userFile.country &&
          this.$refs.userForm.formData.userFile.nation &&
          this.$refs.userForm.formData.userFile.phone &&
          this.$refs.userForm.formData.userFile.education &&
          this.$refs.userForm.formData.userFile.school &&
          this.$refs.userForm.formData.userFile.certificate &&
          this.$refs.userForm.formData.userFile.workExperience &&
          (this.$refs.userForm.formData.userFile.bankAccount || this.$refs.userForm.formData.userFile.alipayAccount)
        ) {
          let reg = /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/
          if (
            this.$refs.userForm.country != 'qt' &&
            this.$refs.userForm.country != 'gat' &&
            this.$refs.userForm.formData.userFile.contractType != '意向全职' &&
            this.$refs.userForm.formData.userFile.contractType != '兼职'
          ) {
            if (reg.test(this.$refs.userForm.formData.userFile.alipayAccount.replace(/\s*/g, ''))) {
              await putAction('/sys/user/changeWorkInfoTimer', {})
            }
          } else {
            await putAction('/sys/user/changeWorkInfoTimer', {})
          }
        }
      }
      setTimeout(() => (this.btnLoading = false), 2000)
      this.$refs.userForm.saveForm(async () => {
        // 先把新用户登录后要看人员须知那些隐藏掉吧，一上来就填信息就行
        putAction('/sys/user/changeNeedUserGuideFalse').then(async ({ success }) => {
          if (success) {
            // this.$message.success('您已完成新手指引')
            this.userInfo.userFile.needUserGuide = false
            Vue.ls.set(USER_INFO, this.userInfo, 7 * 24 * 60 * 60 * 1000)
            this.tab = '2'
          } else {
            // this.$message.error('新手指引完成失败了，请联系管理员')
          }
        })
      }, false)
    },
    saveSelf() {
      if (this.formData.userFile.paymentType == 'EN_ACCOUNT') {
        this.formData.alipayOtherAccount =
          this.formData.userFile.bankAccount || this.formData.userFile.alipayOtherAccount
      }
      if (this.formData.userFile.alipayOtherAccount) {
        this.formData.alipayOtherAccount = this.formData.userFile.alipayOtherAccount
      }
      if (this.formData.userFile.bankAccount) {
        this.formData.alipayOtherAccount = this.formData.userFile.bankAccount
      }
      this.$refs['selfForm'].validate(async valid => {
        if (valid) {
          if (
            this.country != 'qt' &&
            this.country != 'gat' &&
            this.formData.userFile.contractType != '意向全职' &&
            this.formData.userFile.contractType != '兼职'
          ) {
            if (this.formData.userFile.alipayAccount) {
              this.formData.userFile.alipayAccount = this.formData.userFile.alipayAccount.replace(/\s*/g, '')
            }
          } else {
            this.formData.userFile.alipayAccount = this.formData.userFile.alipayOtherAccount
          }
          delete this.formData.alipayOtherAccount
          delete this.formData.userFile.alipayOtherAccount
          await putAction('/sys/user/edit_user_file', this.formData)
          try {
            this.$message.success('保存成功')
            await putAction('/sys/user/changeWorkInfoTimer', {})
            window.location.reload()
            this.selfInfoShow = false
          } catch (err) {
            console.log(err)
          }
        }
      })
    },

    async saveWork() {
      let volid = true
      let volidMsg = ''
      if (!this.scheduleIsDaily) {
        if (this.formData.userFile.workDays.indexOf(1) == -1) {
          this.labelList = this.labelList.filter(item => item.week != 'MONDAY')
        }
        if (this.formData.userFile.workDays.indexOf(2) == -1) {
          this.labelList = this.labelList.filter(item => item.week != 'TUESDAY')
        }
        if (this.formData.userFile.workDays.indexOf(3) == -1) {
          this.labelList = this.labelList.filter(item => item.week != 'WEDNESDAY')
        }
        if (this.formData.userFile.workDays.indexOf(4) == -1) {
          this.labelList = this.labelList.filter(item => item.week != 'THURSDAY')
        }
        if (this.formData.userFile.workDays.indexOf(5) == -1) {
          this.labelList = this.labelList.filter(item => item.week != 'FRIDAY')
        }
        if (this.formData.userFile.workDays.indexOf(6) == -1) {
          this.labelList = this.labelList.filter(item => item.week != 'SATURDAY')
        }
        if (this.formData.userFile.workDays.indexOf(7) == -1) {
          this.labelList = this.labelList.filter(item => item.week != 'SUNDAY')
        }
      }
      this.weekTableData.forEach(i => {
        this.labelList
          .filter(
            item => item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1
          )
          .forEach(item => {
            if (i.departAndPosition == item.depart.departName + '-' + item.position.positionName) {
              if (i[item.week]) {
                item.count = i[item.week]
              } else if (!i[item.week] && item.week != 'WORK_DAY') {
                if (!this.scheduleIsDaily && !this.isAdmin) {
                  volid = false
                  volidMsg =
                    item.depart.departName +
                    '-' +
                    item.position.positionName +
                    '（' +
                    this.weekMap[item.week] +
                    '工作数量未填写）'
                }
              }
            }
          })
      })
      if (this.scheduleIsDaily && !this.isAdmin) {
        this.labelList
          .filter(
            item =>
              item.week == 'WORK_DAY' &&
              (item.depart.departName.indexOf('日语部') > -1 || item.depart.departName.indexOf('韩语部') > -1)
          )
          .forEach(item => {
            if (!item.count) {
              volid = false
              volidMsg = item.depart.departName + '-' + item.position.positionName + '（工作数量未填写）'
            }
          })
      }
      if (this.isAdmin) volid = true
      if (!volid) {
        this.$message.error(volidMsg)
        return
      }

      const rest = await putAction('/sys/user/edit_user_file', {
        userFile: {
          workDays: this.formData.userFile.workDays,
          id: this.formData.userFile.id,
          userId: this.formData.userFile.userId,
          needWorkDays: false,
          scheduleIsDaily: this.scheduleIsDaily == 1
        },
        id: this.formData.id
      })
      if (rest.code == 200) {
        let data = {
          dataList: this.labelList,
          userId: this.userInfo.id,
          scheduleIsDaily: this.scheduleIsDaily == 1
        }
        const res = await postAction('/taskSchedule', data)
        if (res.code == 200) {
          this.$message.success('保存成功')
          window.location.reload()
          this.workVisible = false
        } else {
          this.$message.error(res.msg || res.message)
        }
      } else {
        this.$message.error(rest.msg || rest.message)
      }
    },
    openImg(file) {
      this.$refs.userForm.upImg(file)
    },
    handleWorkChange(value, departAndPosition, column) {
      const newData = [...this.weekTableData]
      const target = newData.find(item => departAndPosition === item.departAndPosition)
      if (target) {
        target[column] = value
        this.weekTableData = newData
      }
    },
    handleChange(value) {
      this.searchCityId = value
      this.cityName = this.citys.find(item => item.id == this.searchCityId).name
      this.isSearchCity = false
      localStorage.setItem(
        'CITY',
        JSON.stringify({
          searchCityId: this.searchCityId,
          cityName: this.cityName
        })
      )
      this.getWeather()
    },
    async handleSearch(value) {
      const res = await axios.get(
        'https://geoapi.qweather.com/v2/city/lookup?location=' + value + '&key=77117740be634f38a85d1871f5fd04e3'
      )
      if (res.data.code == 200) {
        this.citys = res.data.location
      } else {
        this.$message.error('天气请求超过限制')
      }
    },
    openCitySearch() {
      this.isSearchCity = true
    },
    async changeMotto() {
      await putAction('/sys/user/motto?now=' + this.motto)
      this.mottoEdit = false
    },
    editMotto() {
      this.mottoEdit = true
      this.$nextTick(() => {
        this.$refs.mottoInput.focus()
      })
    },
    async getFirstData(isRe) {
      if (this.$refs.homeMobileCalendar&&this.$refs.homeMobileCalendar[0]) {
        this.$refs.homeMobileCalendar[0].msgShow = false
      }
      const res = await getAction('/data/index/first?pol=10')
      if (this.$refs.homeMobileCalendar&&this.$refs.homeMobileCalendar[0]) {
        this.$refs.homeMobileCalendar[0].msgShow = true
      }
      try {
        if (res.code == 200) {
          this.firstData = res.data
          if (res.data.user && res.data.user.motto) this.motto = res.data.user.motto
          if (res.data.headwear) this.pendant = res.data.headwear
          console.log(this.firstData, '首页数据')
          if (!this.firstData.signIn || !this.firstData.signIn.signed) {
            localStorage.removeItem('toDayText')
          }
          if (localStorage.getItem('toDayText')) {
            this.toDayText = JSON.parse(localStorage.getItem('toDayText'))
            if (!isRe) {
              setTimeout(() => {
                this.$refs.backIcon.className = 'iconfont icon front ' + this.toDayText.name
                this.$refs.backIcon.style = 'color:' + this.toDayText.color
              }, 0)
            }
          }
          /*         if (this.$refs.fAnds) {
          this.$refs.fAnds[0].palyRay()
        } */
          if (this.$refs.homeMobileCalendar) {
            this.$nextTick(() => {
              if (this.$refs.homeMobileCalendar[0].titleTimer) {
                clearInterval(this.$refs.homeMobileCalendar[0].titleTimer)
              }
              this.$refs.homeMobileCalendar[0].getWidth()
            })
          }
          if (this.$refs.weather) this.$refs.weather.changeBack(this.firstData)
          this.productLoading = false
          this.startLoading = false
        } else if (res.code == 428) {
          //个人信息48小时弹窗
          this.selfInfoShow = true
        } else if (res.code == 429) {
          if (!this.userModalVisible) {
            this.workVisible = true
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    rgb() {
      //rgb颜色随机
      const r = Math.floor(Math.random() * 256)
      const g = Math.floor(Math.random() * 256)
      const b = Math.floor(Math.random() * 256)
      return `rgb(${r},${g},${b})`
    },
    async boxClick() {
      document.getElementById('mood').style.transform = 'rotateY(540deg)'
      let icon = iconNames[Math.floor(Math.random() * (iconNames.length - 1))]
      let color = this.rgb()
      let timer = setInterval(() => {
        document.getElementById('mood').getElementsByClassName('back')[0].className = 'back iconfont icon ' + icon.name
        document.getElementById('mood').getElementsByClassName('back')[0].style = 'color:' + color
        this.toDayText = {
          text: toDayTexts[Math.floor(Math.random() * (toDayTexts.length - 1))],
          name: icon.name,
          color
        }
        localStorage.setItem('toDayText', JSON.stringify(this.toDayText))
        this.$nextTick(() => {
          this.$refs.backIcon.className = 'back iconfont icon ' + icon.name
          this.$refs.backIcon.style = 'color:' + color
        })
        this.getFirstData(true)
        clearTimeout(timer)
      }, 500)
      const res = await putAction('/signIn')
      if (res.code == 200) {
        this.$message.success('打卡成功')
        if (this.firstData.user && this.firstData.user.continuedSignIn) {
          this.firstData.user.continuedSignIn = this.firstData.user.continuedSignIn - 0 + 1
        } else {
          this.firstData.user.continuedSignIn = 1
        }
      }
    },
    handleUpChange({ file, fileList }) {
      return false
    },
    async upImg(file) {
      await this.beforeUpload(file)
      const token = Vue.ls.get(ACCESS_TOKEN)
      let formData = new FormData()
      this.ossUploadData.key = this.ossUploadData.key + '.' + file.type
      for (let key in this.ossUploadData) {
        formData.append(key, this.ossUploadData[key])
      }
      formData.append('file', file)
      axios
        .post(this.ossUploadUrl, formData, {
          'Content-Type': 'multipart/form-data;',
          headers: {
            'X-Access-Token': token
          }
        })
        .then(() => {
          file.status = 'done'
          this.handleChangeUpload({ file })
        })
    },
    handleChangeUpload(info, index) {
      if (info.file.status === 'uploading') {
        this.avatarLoading = true
        return
      }
      if (info.file.status === 'done') {
        let params = this.getSaveFileParams(info.file)
        this.formData.userFile.avatar = params.filePath
        this.removeOssFilePolicy(info.file)
        putAction('/sys/user/edit_user_file', this.formData).then(res => {
          this.avatarLoading = false
          if (res.success) {
            this.$message.success(res.message)
            Vue.ls.set(USER_INFO, this.formData, 7 * 24 * 60 * 60 * 1000)
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    openCropper(url) {
      this.$refs.userCropperModel.openModal(url)
    },
    beforeCroUpload(file) {
      this.fileList = [...this.fileList, file]
      const url = window.URL ? window.URL.createObjectURL(file) : window.webkitURL.createObjectURL(file)
      this.openCropper(url)
      return false
    },
    async getWeather() {
      this.loading = true
      if (localStorage.getItem('CITY')) {
        this.searchCityId = JSON.parse(localStorage.getItem('CITY')).searchCityId
        this.cityName = JSON.parse(localStorage.getItem('CITY')).cityName
      }
      axios
        .get(
          'https://devapi.qweather.com/v7/weather/now?location=' +
            this.searchCityId +
            '&key=77117740be634f38a85d1871f5fd04e3'
        )
        .then(res => {
          this.loading = false
          if (res.data.code == 200) {
            this.dateData = { ...Calendar.data(), ...res.data }
            console.log(this.dateData, '天气数据')
            if (this.dateData.now.text.indexOf('晴') > -1) {
              this.weather = 'sun' //晴
            } else if (this.dateData.now.text.indexOf('云') > -1) {
              this.weather = 'cloud' //多云
            } else if (
              this.dateData.now.text.indexOf('阴') > -1 ||
              this.dateData.now.text.indexOf('雨') > -1 ||
              this.dateData.now.text.indexOf('雪') > -1
            ) {
              this.weather = 'rain' //阴或雨
            }
          } else {
            this.loading = false
            this.$message.error('天气请求超过限制')
          }
        })
        .catch(err => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.select-pendant {
  position: absolute;
  top: -14px;
  width: 35px;
  height: 35px;
  left: 28px;
  text-align: center;
  z-index: 1;
  img {
    width: 80%;
    height: auto;
  }
}
/deep/ .ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .weather-text-center-top .ant-upload.ant-upload-select-picture-card {
  border-radius: 50% !important;
  width: 96px;
  height: 96px;
  padding: 1px;
  border: 1px solid rgba(0, 0, 0, 0) !important;
  background-color: rgba(0, 0, 0, 0);
}
/deep/ .ant-upload.ant-upload-select-picture-card:hover {
  border: 1px solid var(--theme-color) !important;
}
/deep/ .weather-text-center-top .ant-upload-picture-card-wrapper {
  width: 95px !important;
}
.weather-card {
  height: 260px;
  position: relative;
  .weather-text {
    position: absolute;
    padding: 15px 42px 15px 42px;
    top: 0;
    left: 0;
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .weather-text-card {
      width: 30%;
      .weather-text-card-top {
        width: 100%;
        height: 60%;
        > div {
          margin-bottom: 3px;
        }
        .weather-title {
          font-size: 1.5rem;
        }
      }
      .weather-text-card-top-right {
        width: 100%;
        height: 60%;
        flex-wrap: wrap;
        > div {
          width: 100%;
          display: flex;
          justify-content: end;
          align-items: center;
          margin-bottom: 10px;
        }
        .weather-title {
          font-size: 1.5rem;
        }
      }
      .weather-text-card-bottom {
        width: 100%;
        height: 30%;
        margin-top: 5%;
        display: flex;
        align-items: end;
        padding-bottom: 20px;
      }
      .weather-text-card-bottomRight {
        width: 100%;
        height: 30%;
        display: flex;
        align-items: end;
        justify-content: end;
        flex-wrap: wrap !important;
        margin-top: 5%;
        .box {
          margin-right: 2px;
        }
        .sign {
          width: 100% !important;
          text-align: center;
          cursor: pointer;
        }
        > div {
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: end;
          > div {
            width: 100%;
            text-align: center;
          }
        }
        .today-text {
          justify-content: end;
          width: 95px;
          > div {
            width: 91px;
          }
          .sign-num {
            color: rgba(0, 0, 0, 0.45);
            text-shadow: 0px 0px 5px #fff;
            font-size: 12px;
          }
        }
      }
      .weather-text-center-top {
        width: 100%;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: end;
        .img-div {
          width: 95px;
          height: 95px;
          border-radius: 50% !important;
          overflow: hidden;
          img {
            width: 95px;
            height: auto;
          }
        }
      }
      .weather-text-center-bottom {
        width: 100%;
        height: 30%;
        margin-top: 5%;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: end;
        padding-bottom: 20px;
        > div {
          width: 100%;
          margin-top: 3px;
        }
      }
    }
  }
}
.left-card-bottom {
  height: 540px;
  margin-top: 30px;
  .ant-col {
    height: 100%;
  }
}
.left-card,
.right-card {
  min-height: 990px;
  padding-top: 15px;
}
.left-card-bottom-lt {
  height: 210px;
  width: 100%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0.8rem;
  border-radius: 10px;
}
.left-card-bottom-lb {
  height: 410px;
  width: 100%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0.8rem;
  border-radius: 10px;
}
.left-card-bottom-rt {
  height: 410px;
  width: 100%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0.8rem;
  border-radius: 10px;
}
.left-card-bottom-rb {
  height: 210px;
  width: 100%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0.8rem;
  border-radius: 10px;
}
.left-card {
  padding-left: 48px;
}
.right-card {
  padding-left: 30px;
  padding-right: 48px;
}
.right-top-card {
  height: 600px;
}
.right-bottom-card {
  height: 310px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0.8rem;
}
.box {
  width: 65px;
  height: 65px;
  position: relative;
  transition: all 1s; /* 过渡效果持续0.4秒 */
  transform-style: preserve-3d; /* 为子元素开启 三维立体环境 */
}
.box:hover {
  /* transform: rotateY(540deg);  */
  cursor: pointer;
}
.front,
.back {
  span {
    line-height: 65px;
  }
  &::before {
    font-size: 65px;
    line-height: 65px;
  }
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 25px;
  text-align: center;
  backface-visibility: hidden; /* 隐藏被旋转的 div 元素的背面 */
}
.front {
  z-index: 1; /* 设置层的优先级，否则第二个子元素会覆盖这个第一个子元素 */
}
.back {
  transform: rotateY(540deg); /* 以 Y 轴旋转 180 度（如果不加这个设置，盒子反过来看到的字的反面） */
  transition: color 1s;
}
.all-card {
  padding-bottom: 30px;
  min-height: 990px;
  display: flex;
  flex-wrap: wrap;
}
.weather-icon::before {
  font-size: 30px;
}
//媒体查询
@media (max-width: 1535px) {
  .right-card,
  .left-card {
    width: 100% !important;
  }
  .right-card {
    padding-left: 48px;
  }
  .left-card {
    padding-right: 48px;
  }
}
/deep/ .ant-input-number-handler-wrap {
  display: none;
}
</style>
